import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import {FeatherCheckIcon} from '../../../assets/icons'


const StyledCircle = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  align-items: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `
    transform ${theme.global.duration} ease-in,
    background-color ${theme.global.duration} ease-in
  `};
  border: 1px solid ${({theme}) => `${theme.colors.gray}50`};
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;

  ${media.down('mobile')(css`
    transform: translateY(-55%);
  `)}
`

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding: 0 0.5rem;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &:hover,
  &:focus {
    ${StyledCircle} {
      transform: translateY(-50%) scale(1.1);
    }

    svg {
      path,
      rect {
        opacity: 0.5;
        stroke: ${({theme}) => theme.colors.gray};
      }
    }
  }
`

const StyledInput = styled.input`
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;

  &:checked + ${StyledCircle} {
    transform: translateY(-50%) scale(1.1);
    border: 1px solid ${({theme}) => theme.colors.primary};
    background-color: ${({theme}) => theme.colors.primary};

    svg {
      path,
      rect {
        opacity: 1;
        stroke: ${({theme}) => theme.colors.background};
      }
    }

    ${media.down('mobile')(css`
      transform: translateY(-55%);

      svg {
        transform: translateY(10%) scale(0.8);
      }
    `)}
  }
`

const StyledText = styled.span`
  padding-left: 0.75rem;
  font-size: 0.875rem;

  ${media.down('mobile')(css`
    padding-left: 0.5rem;
  `)}
`

const RadioButton = ({name, value, onChange, required, checked, ...props}) => {
  const handleChange = (e) => {
    const {value} = e.target
    if (onChange) onChange(name, value, e)
  }

  return (
    <StyledLabel {...props}>
      <StyledInput
          type="radio"
          name={name}
          value={value}
          onChange={handleChange}
          required={required}
          checked={checked}
      />
      <StyledCircle>
        <FeatherCheckIcon />
      </StyledCircle>
      <StyledText>{value}</StyledText>
    </StyledLabel>
  )
}

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  checked: PropTypes.bool,
}

export default RadioButton
