import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'


const StyledContactContainer = styled.div`
  display: flex;
  padding-top: 9.25rem;

  ${media.down('mobile')(css`
    flex-direction: column;
    padding-top: 5rem;
 `)}
`

const ContactContainer = ({children, ...props}) => {
  return (
    <StyledContactContainer {...props}>
      {children}
    </StyledContactContainer>
  )
}

ContactContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContactContainer
