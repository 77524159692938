import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {mapValues} from 'lodash-es'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'
import useLang from '../hooks/useLang'
import SectionCallToAction from '../components/molecules/SectionCallToAction'
import SideTitleSection from '../components/containers/SideTitleSection'
import Contacts from '../components/molecules/Contact/Contacts'
import ContactContainer from '../components/molecules/Contact/ContactContainer'
import CompanyInformation from '../components/molecules/Contact/CompanyInformation'
import ContactForm from '../components/molecules/Contact/ContactForm'


const Contact = ({data, pageContext: {lang: pageLang}}) => {
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data.markdownRemark.frontmatter[lang]

  return (
    <AppShell title={pageData?.menuText} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar title={pageData.menuText} lang={lang} langToSlug={langToSlug} />
      <main>
        <ContactContainer>
          <SideTitleSection
              title={pageData.hero.title}
              mobileTitleVisible
              largeTitle
              fadedSideText={pageData.hero.sideText}
          >
            <Contacts
                contacts={pageData.hero.contacts}
            >
              <ContactForm formContent={pageData.form} lang={lang} />
            </Contacts>
          </SideTitleSection>
          <CompanyInformation
              title={pageData.companyInformation.title}
              address={pageData.companyInformation.information}
              googleMapsLink={pageData.companyInformation.googleMaps.link}
              googleMapsText={pageData.companyInformation.googleMaps.text}
              invoiceInformation={pageData.companyInformation.invoiceInformation}
              businessInformation={pageData.companyInformation.businessInformation}
          />
        </ContactContainer>
        <SectionCallToAction
            bottom
            link={pageData.callToAction.action.link}
            linkText={pageData.callToAction.action.text}
        >
          {pageData.callToAction.title}
        </SectionCallToAction>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Contact($id: String!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        ...ContactPageFragment
      }
    }
  }
`

Contact.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }),
}

export default Contact
