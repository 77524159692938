import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {For} from 'babel-plugin-jsx-control-statements'
import {media} from '../../../utils'
import theme from '../../../theme/theme'
import BaseLink from '../../atoms/BaseLink'


const StyledContactContainer = styled.div`
  padding: 4.375rem 0 0 12.5rem;
  width: 80%;

  ${media.down('mobile')(css`
    padding: 3rem 1.5rem;
    width: 100%;
 `)}
`

const StyledContactLinksContainer = styled.div`
  display: flex;

  ${media.down('mobile')(css`
    flex-direction: column;
    text-align: left;
  `)}
`

const StyledContactLink = styled(BaseLink)`
  padding-right: 5.5rem;
  line-height: 2;
  color: ${theme.colors.secondary};

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  ${media.down('mobile')(css`
    line-height: 2.5;
    padding-right: 0;
  `)}
`

const Contacts = ({contacts, children, ...props}) => {
  return (
    <StyledContactContainer {...props}>
      <StyledContactLinksContainer>
        <For each="contact" of={contacts} index="idx">
          <StyledContactLink key={idx} to={contact.link} isExternal>{contact.text}</StyledContactLink>
        </For>
      </StyledContactLinksContainer>
      {children}
    </StyledContactContainer>
  )
}

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
}

export default Contacts
